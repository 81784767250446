.Informations {
    width: 100vw;
    padding: 20px;
    min-height: 85vh;
    background-color: rgba(40, 165, 174, 0.2);
    box-sizing: border-box; /* Assure que les paddings sont inclus dans la largeur */
}

.Informations-content {
    max-width: 100%; /* Largeur maximale pour s'adapter à la taille de la fenêtre */
    margin:0 auto;
    padding-top: 50px;
    align-items: center; 
    margin-bottom: 30px;
    display:flex;
    justify-content: center;
}

.Informations span {
    font-weight: bold;
    color:#00AEA5;
    white-space: nowrap;
}

.Informations h1 {
    font-size: 25px;
    font-weight: bold;
    padding-top: 30px;
    padding-bottom: 10px;
    color: #073D77;
    text-align: center;
}

.Informations p {
    font-size: 20px;
    margin-bottom: 10px;
    line-height: 30px;
}

.Informations-left {
    width: 50%;
    padding-top: 50px;
}

.Informations-right {
    width: 50%;
    max-height: 400px;
    max-width: 400px;
}

.Informations-left span.violet {
    border-radius: 20%;
    background-color: #65378D;
    color:white;
    padding-left: 3px;
    padding-right: 3px;
}

.Informations-left span.blue {
    border-radius: 20%;
    background-color: #567EBF;
    color:white;
    padding-left: 3px;
    padding-right: 3px;
}

.Informations-left span.yellow {
    border-radius: 20%;
    background-color: #E8AF1A;
    color:white;
    padding-left: 3px;
    padding-right: 3px;
}

.Informations-left span.pink {
    border-radius: 20%;
    background-color: #E43B61;
    color:white;
    padding-left: 3px;
    padding-right: 3px;
}

.Informations-left span.grey {
    border-radius: 20%;
    background-color: #B7C6E6;
    color:white;
    padding-left: 3px;
    padding-right: 3px;
}

.hide-map {
    display: none;
}

/* Ajout de styles pour les petits écrans */
@media screen and (max-width: 880px) {
    .Informations-content {
        width: 90%; /* Réduit la largeur du contenu */
        flex-direction: column; /* Change la direction pour une disposition verticale */
        align-items: flex-start; /* Aligne les éléments sur le côté */
    }

    .Informations-left,
    .Informations-right {
        width: 100%; /* Prend toute la largeur disponible */
        padding-top: 20px; /* Ajuste l'espacement en haut */
        text-align: left; /* Aligne le texte à gauche */
        max-width: none; /* Supprime les limites de largeur maximale */
        max-height: none; /* Supprime les limites de hauteur maximale */
    }

    .Informations-right {
        padding-left: 0; /* Supprime le padding gauche */
        padding-top: 20px; /* Ajoute un peu d'espacement en haut */
    }

    .Informations-left span,
    .Informations-left h1,
    .Informations p {
        font-size: 16px; /* Réduit la taille du texte */
        padding-bottom: 15px; /* Ajuste l'espacement en bas */
    }
}
