.Super-main {
    background-image: url("../../assets/images/pen.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100vh;
}

.Main {
    display:flex;
    justify-content: center;
    padding-top: 180px;
    height: 70vh;
    padding-left: 20px;
    padding-right: 20px;
}

.Main-left {
    width: 50%;
}

.Main-map-button {
    font-size: 10px;
    background-color: transparent;
    color: black;
    padding: 10px;
    border-radius: 10px;
    border:none;
    margin-top: 10px;
    display: inline-block;
    vertical-align: middle; /* Aligne verticalement le contenu */
    text-decoration: none;
}

.Main-map-button img,
.Main-map-button p {
  display: inline-block; /* Affiche l'image et le texte sur la même ligne */
  vertical-align: middle; /* Aligne verticalement le contenu */
  margin: 0; /* Supprime les marges par défaut */
}

.Main-map-button p {
    padding-left: 5px;
    font-weight:600;
  }
  
.Main-map-button:hover {
    color: white;
    background-color: #00AEA5;
}

.Main-map-button a:visited {
  /* Styles pour les états visités, focus et actifs des liens à l'intérieur du bouton */
  color: white;
}

.Main-left h1 {
    color: white;
    font-size: 50px;
    font-weight: bold;
}

.Main-left p {
    font-size: 20px;
    font-weight: 400;
}

.Main h2 {
    color: #00AEA5;
    font-weight: 900;
    font-size: 30px;
    text-shadow: 1px 1px 1px white;
}

.Main-curseur {
    width: 30px;
    height: 30px;
}

.Main-paragraphe .read-more {
    font-size: 15px;
    background-color: transparent;
    color: #073D77;
    border-radius: 10px;
}

.read-more a:visited {
    /* Styles pour les états visités, focus et actifs des liens à l'intérieur du bouton */
    color: #073D77;
}

.read-more:hover {
    color: white;
    background-color: #00AEA5;
    font-weight: bold;
}

.Main-right {
    width: 350px;
    height: 350px;
    padding-left: 40px;
    padding-right: 40px;
    background: rgba(255,255,255, 0.8);
    margin-left: 10px;
    color:#073D77;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Main-right h3 {
    font-size: 20px;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border:none;
    border-radius: 10px;
    background-color: #00AEA5;
    color: white;
    margin-top: 20px;
}

.Main-right h1 {
    font-size: 30px;
}

.Main-right p {
    font-size: 20px;
    margin-top: 10px;
}

.Main-details {
    width: 1300px;
    margin:0 auto;
    padding-left: 30px;
    padding-right: 30px;
}

.Main-details-right {
    font-size: 10px;
}


.Main-paragraphe .citation {
    font-size: 10px;
    font-style: italic;
    text-align: end;
}

.Main-paragraphe .citation a {
    font-size: 10px;
    font-style: italic;
    text-align: end;
}

.Main-details img {
    width: 500px;
    height: 500px;
}

.Main-details-right .exams {
    width: 500px;
    height: 400px;
}

.Main-paragraphe {
    display: flex;
    justify-content: center;
    align-items: center; 
    padding-bottom: 50px;
}

.Main-paragraphe h3 {
    color: #00AEA5;
    font-size: 15px;
    padding-bottom: 10px;
}

.Main-paragraphe h1 {
    font-size: 35px;
    font-weight: bold;
    padding-bottom: 10px;
    color:#073D77;
}

.Main-paragraphe p{
    font-size: 17px;
    line-height: 30px;
    padding-bottom: 20px;
}

.Main-paragraphe button {
    padding: 5px;
    background-color: transparent;
    border: 0.5px solid #00AEA5;
    color: black;
}

.Main-paragraphe button:active{
    color:initial;
}

.Main-details-left {
    padding-right: 40px;
}


/* Ajout de styles pour les petits écrans */
@media screen and (max-width: 880px) {
    .Super-main {
        height: 100vh;
    }

    .Main {
        flex-direction: column; /* Change la direction pour une disposition verticale */
        align-items: center; /* Centre les éléments horizontalement */
        padding-top: 70px; /* Ajuste l'espacement en haut */
        height: auto; /* Ajuste la hauteur automatiquement */
    }

    .Main-left,
    .Main-right {
        width: 100%; /* Prend toute la largeur disponible */
        padding: 20px; /* Ajoute un espacement intérieur */
        text-align: center; /* Centre le texte */
    }

    .Main-left h1 {
        font-size: 30px; /* Réduit la taille du titre */
    }

    .Main-left p {
        font-size: 16px; /* Réduit la taille du paragraphe */
    }

    .Main-map-button {
        font-size: 8px; /* Réduit la taille du bouton */
        padding: 5px; /* Ajuste l'espacement */
    }

    .Main-right {
        margin-top: 20px; /* Espacement supplémentaire en haut */
        width: 80%; /* Ajuste la largeur */
        height: 25vh;
    }

    .Main-paragraphe {
        display: flex;
        flex-direction: column; /* Passer à une disposition verticale */
        align-items: center; /* Centrer horizontalement les éléments */
        text-align: center; /* Centrer le texte */
        padding-bottom: 50px;
    }

    .Main-details-left,
    .Main-details-right {
      width: 100%; /* Prend la largeur complète */
      text-align: center; /* Centre le texte horizontalement */
      margin-bottom: 20px; /* Espace entre les éléments */
    }


    .Main-details img {
        max-width: 80%; /* Remplit la largeur disponible */
        height: auto; 
    }

    .Main-paragraphe h1 {
        font-size: 25px; /* Réduit la taille du titre */
    }

    .Main-paragraphe p {
        font-size: 14px; /* Réduit la taille du paragraphe */
        line-height: 24px; /* Ajuste la hauteur de ligne */
    }

    .Main-paragraphe button {
        font-size: 8px; /* Réduit la taille du bouton */
        padding: 3px; /* Ajuste l'espacement */
    }
}

@media screen and (max-width: 1300px) {
    .Main-details {
        width: auto;
        padding-top: 30px;
    }
}