.header-information {
    background-color: rgba(40, 165, 174, 0.2);
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:10px;
    padding-bottom: 10px;
    color: #073D77;
}

.header-information h2 {
    font-size: 18px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border:none;
    border-radius: 10px;
    background-color: #073D77;
    color: white;
    max-height: 55px;
    max-width: 210px;
    text-align: center;
    margin-left: 10px;
}

span {
    white-space: nowrap;
}