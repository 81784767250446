.Super-legal-mentions {
    height: 100vh;
}

.Legal-mentions {
    text-align: justify;
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    color: black;
}

.Legal-mentions h1 {
    padding-bottom: 10px;
    font-size: 30px;
    text-align: center;
    color: #073D77;
}

.Legal-mentions h2 {
    padding-bottom: 10px;
    padding-top: 10px;
    font-size: 15px;
    color: #073D77;
}

.Legal-mentions p {
    padding-top: 5px;
}