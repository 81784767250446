.App {
  /*background-image: url("../assets/images/centre_neurologiqueV1.jpg");*/
  font-family: 'Montserrat', sans-serif;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.hide-map {
  display: none;
}



/*RESPONSIVE*/
