.Footer {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #00AEA5;
}

.Footer h3 {
    font-size: 22px;
    padding-top: 20px ;
    font-weight: bold;
    padding-bottom: 10px;
}

.Footer p {
    font-size: 20px;
    padding-bottom: 10px;
    line-height: 22px;
}

.Footer-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 10px;
}

.Footer-right{
    width: 50%;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Map-container {
    width: 100%; /* Prend la largeur totale du conteneur parent */
    max-width: 100%; /* Pour assurer la largeur maximale de la carte */
    height: 100%; /* Ajuste la hauteur comme nécessaire */
    padding-top: 60px;
}

.Footer button {
    font-size: 17px;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    border:none;
    border-radius: 10px;
    background-color: #073D77;
    color: white;
    font-weight: bold;
}

.Footer-rdv a {
    color: white;
}

.Legal-mentions {
    font-size: 13px;
    text-align: center;
    color: gray;
    padding-top: 15px;
    padding-bottom: 15px;
}

.Legal-mentions a {
    text-decoration: none;
    font-size: 15px;
}

@media screen and (max-width: 880px) {
    .Footer h3,
    .Footer p {
      font-size: 18px; /* Réduire la taille du texte pour les écrans plus petits */
    }
  
    .Footer-left,
    .Footer-right {
      width: 100%; /* Utilisation de la largeur complète sur les petits écrans */
      margin-left: 20px;
      margin-right: 20px;
    }

    .Footer {
        flex-direction: column; 
        align-items: center;
    }
  }