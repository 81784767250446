.Exams {
    width: 90%; /* Largeur pour les grands écrans */
    max-width: 1200px; /* Limite la largeur pour éviter le débordement */
    margin:0 auto;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-top: 50px;
}

.Exams-introduction p {
    font-size:18px ;
    font-style: italic;
    text-align: center;
}

.Exams-paragraphe {
    text-align: justify;
    line-height: 30px;
    padding-bottom: 30px;
}

.Exams h1 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 30px;
    color:#073D77 ;
}

.Exams h2 {
    font-size: 25px;
    font-weight: bold;
    color: #00AEA5;
    padding-bottom: 15px;
    padding-top: 30px;
}

.Exams h3 {
    font-size: 20px;
    text-decoration: underline;
    padding-bottom: 15px;
    padding-top: 30px;
}

.Exams p {
    font-size: 20px;
    padding-bottom: 30px;
    line-height: 30px;
}

.Exams-type img {
    width: 80%; 
    max-width: 100%; 
    height: auto; 
    margin: 10px 0; 
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

img.lombaire {
    width: 350px;
    height: 400px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.Exams-type{
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 880px) {
    .Exams {
        width: 80%;
    }

    .Exams-paragraphe p {
        font-size: 15px;
    }


    .Exams-type img {
        width: 90%; /* Ajustez la largeur des images selon votre mise en page */
        max-width: 100%;
        height: auto;
        margin: 10px 0; /* Ajustez les marges selon vos besoins */
        display: block;
        margin-left: auto;
        margin-right: auto;
      }

}

