.Diseases {
    width: 90%; /* Largeur pour les grands écrans */
    max-width: 1200px; /* Limite la largeur pour éviter le débordement */
    margin: 0 auto;
    padding-top: 50px;
}

.Diseases-introduction {
    padding-bottom: 50px;
}

.Diseases-introduction h1 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 30px;
    color: #073D77;
}

.Diseases-introduction p {
    font-size: 18px;
    text-align: center;
    padding-bottom: 30px;
    line-height: 30px;
    font-style: italic;
}

.Diseases-informations {
    margin-top: 10px;
    margin-bottom: 50px;
}

.Diseases-informations h2 {
    color: #00AEA5;
    font-size: 25px;
    padding-bottom: 7px;
    font-weight: bold;
}

.Diseases-informations p {
    font-size: 20px;
    line-height: 30px;
}


@media screen and (max-width: 880px) {
    .Diseases {
        width: 80%;
    }

    .Diseases-informations p {
        font-size: 15px;
    }
}