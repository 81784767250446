.navbar {
    display: flex;
    align-items:center;
    justify-content: space-between;
    color: black;
    padding: 10px;
    padding-left: 5%;
    padding-right: 10%;
    min-height: 75px;
  }


.navbar-link, a {
  text-decoration: none;
  font-size: 23px;
}

.navbar {
  padding-top: 30px;
  margin-left:10px;
}

.navbar-link {
  padding-left: 20px;
  color:white;
  text-shadow: 1px 1px 1px #073D77;
}


img {
  height: 110px;
  width: 240px;
}

.active {
  color : #00AEA5;
}

.navbar_burger {
  width: 40px;
  height: 40px;
  background: transparent;
  border: none;
  color: inherit;
  display: none;
}

/*RESPONSIVE*/


/* Media query pour les écrans jusqu'à 768px de largeur */
@media screen and (max-width: 1220px) {
  .navbar-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: -100vw;
    bottom: 0;
    height: 100vh;
    padding: 2rem;
    background-color: #073D77;
  }

  .show-nav .navbar-links {
    width: 100vw;
    right: 0;
  }

  .navbar-link {
    display: block;
    padding-bottom: 1rem;
    font-size: 4vw;
  }

  .navbar_burger {
    display: block;
    position: fixed;
    top:4rem;
    right: 1rem;
  }

  .navbar_burger:hover {
    cursor: pointer;
  }

  .burger-bar,
  .burger-bar::before,
  .burger-bar::after {
    display: block;
    width: 40px;
    height: 3px;
    position: relative;
    border-radius: 3px;
    background: #00AEA5;
    transition: all .5s ease-in-out;
  }

  .burger-bar::before,
  .burger-bar::after {
    content:"";
    position: absolute;
    left: 0;
  }

  .burger-bar::before {
    transform: translateY(-12px);
  }

  .burger-bar::after {
    transform: translateY(12px);
  }

  .show-nav .burger-bar::before{
    transform: rotate(45deg);
  }

  .show-nav .burger-bar::after{
    transform: rotate(-45deg);
  }

  .show-nav .burger-bar {
    background: transparent;
    width: 0;
  }

}

@media screen and (max-width: 1500px) {
  .navbar {
    flex-wrap: wrap;
  }

}