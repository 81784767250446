.Team {
    width: 90%; /* Largeur pour les grands écrans */
    max-width: 1200px; /* Limite la largeur pour éviter le débordement */
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    align-items: center;
    margin-bottom: 30px;
    line-height: 30px;
}

.Team-introduction p {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
    font-style: italic;
    padding-bottom: 30px;
}

.Team h1 {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
    padding-bottom: 30px;
    color:#073D77 ;
}


.Team-doctor {
    padding-top: 50px;
    line-height: 30px;
}

ul {
    list-style-type: disc; /* Utilisation de puces rondes par défaut */
    margin-left: 50px;
}

.Team-secretary {
    padding-top: 50px;
}


.Team-paragraphe h2 {
    margin-top: 25px;
    font-size: 22px;
    font-weight: bold;
}

.Team-paragraphe p {
    font-size: 20px;
    padding-bottom: 30px;
    line-height: 30px;
}

.Team img {
    width: 400px;
    height: 400px;
    margin:0 auto;
}

.secretary {
    font-weight: bold;
}

.optam {
    padding-top: 30px;
    font-style: italic;
}

@media screen and (max-width: 880px) {
    .Team {
        width: 80%; /* Réduire un peu plus la largeur pour les écrans plus petits */
    }

    .Team-introduction {
        font-size: 16px; /* Ajuster la taille du texte pour les petits écrans */
    }

    .Team h1 {
        font-size: 24px; /* Ajuster la taille du titre pour les petits écrans */
        padding-bottom: 15px; /* Ajuster l'espacement */
    }

    .Team-paragraphe {
        font-size: 14px; /* Ajuster la taille du texte pour les petits écrans */
        line-height: 22px; /* Ajuster l'espacement des lignes */
        padding-bottom: 15px; /* Ajuster l'espacement en bas */
        width: 90%;
    }

    .Team-paragraphe h2 {
        margin-top: 15px; /* Ajuster l'espacement en haut pour les petits écrans */
        font-size: 18px; /* Ajuster la taille du titre pour les petits écrans */
    }

    .Team-paragraphe p {
        font-size: 15px;
        word-wrap: break-word;
    }

    .Team img {
        max-width: 90%; 
        height: auto; 
        margin: 10px 0;
    }
}