.NotFound {
    padding: 10px;
    font-size: 13px;
    text-align: center;
    margin-bottom: 50px;
}

.NotFound span {
    font-weight: bold;
}

.NotFound img {
    width: 200px;
    height: 150px;
}